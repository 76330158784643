<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip class="ma-2" color="blue" label x-small v-bind="attrs" v-on="on">
        <strong class="white--text">!</strong>
      </v-chip>
    </template>
    <span>
      <div class="text--white">
        Lançado por {{ user ? user.name : professor ? professor.nome : "- - -" }}
      </div>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    professor: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style></style>
