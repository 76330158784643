<template>
  <main-template>
    <v-row>
      <v-col>
        <v-btn color="primary" x-small text @click="() => $router.push({ name: 'alunos' })">
          <v-icon left> fa fa-angle-left </v-icon>
        </v-btn>
        <span class="text-h4">Histórico de Matrículas</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-alert v-if="message" border="top" color="primary lighten-2" dark>
          {{ message }}
        </v-alert>

        <v-data-table
          :headers="table.headers"
          :items="historicoList"
          :loading="carregandoHistorico"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="matricula.estaDeletado ? ['deleted'] : []"
                v-for="matricula of items"
                :key="matricula.id"
              >
                <td>{{ matricula.id }}</td>
                <th>{{ matricula.aluno.codigo ? matricula.aluno.codigo : "- - -" }}</th>
                <td>{{ matricula.aluno.nomecompleto }}</td>
                <td class="text-left">
                  {{ matricula.turma.descricao }}
                </td>
                <td>{{ matricula.serie.descricao }}</td>
                <td>{{ matricula.turma.turno.descricao }}</td>
                <td>{{ matricula.ano.descricao }}</td>
                <td>{{ matricula.situacao ? matricula.situacao : "- - -" }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  computed: {},
  data() {
    return {
      message: null,
      carregandoHistorico: false,
      dataTableFilter,
      search: "",
      historicoList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código", value: "codigo" },
          { text: "Aluno", value: "nomeAluno" },
          { text: "Turma", value: "nomeTurma" },
          { text: "Serie", value: "nomeSerie" },
          { text: "Turno", value: "nomeTurno" },
          { text: "Ano", value: "nomeAno" },
          { text: "Situação", value: "nomeSituacao" },
        ],
      },
    };
  },
  mounted() {
    this.carregandoHistorico = true;
    this.$loaderService.open("Carregando Historico");
    this.listarHistorico();
    this.carregandoHistorico = false;
    this.$loaderService.close();
  },
  watch: {},
  methods: {
    async listarHistorico() {
      this.carregandoMatriculas = true;
      const { aluno_id } = this.$route.params;
      const response = await this.$services.matriculasService.historico(aluno_id);
      if (!response.data.alunos.length) this.message = "Aluno Sem Matrículas";
      this.historicoList = response.data.alunos;
      this.carregandoMatriculas = false;
    },
  },
};
</script>

<style></style>
