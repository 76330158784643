import Axios from "@/plugins/Axios";
import store from "@/store";
import Usuario from "@/Models/User";

export class UsuariosService {
  async syncAll() {
    const response = await Axios().get("users");
    const usuarios = response.data.map((usuario) => new Usuario(usuario));
    store.commit("Usuarios/setUsuarios", usuarios);
    return usuarios;
  }

  async syncAllPaginate(pageNumber, query) {
    const response = await Axios().get(`users/paginate?query=${query}&page=${pageNumber}`);
    return response;
  }

  async getUsuario(usuario_id) {
    const response = await Axios().get(`users/${usuario_id}`);
    const usuario = new Usuario(response.data);
    return usuario;
  }

  async criarUsuario(usuario) {
    const response = await Axios().post("users", usuario);
    const novaUsuario = response.data;
    store.commit("Usuarios/adicionarUsuario", novaUsuario);
    return novaUsuario;
  }

  async atualizarUsuario(usuario) {
    const response = await Axios().put(`users/${usuario.id}`, usuario);
    const usuarioAtualizada = response.data;
    store.commit("Usuarios/atualizarUsuario", usuarioAtualizada);
    return usuarioAtualizada;
  }

  async deletarUsuario(usuario) {
    const response = await Axios().delete(`users/${usuario.id}`);
    const usuarioDeletada = response.data;
    // store.commit("Usuarios/deletarUsuario", usuario.id);
    return usuarioDeletada;
  }

  async recuperarUsuario(usuario) {
    return this.atualizarUsuario({ ...usuario, deleted_at: null });
  }

  async atualizarSituacao(usuario, novaSituacao) {
    const response = await Axios().put(`users/${usuario}/atualizarSituacao`, {
      ...usuario,
      situacao: novaSituacao,
    });
    const aulaAtualizada = response.data;
    return new Usuario(aulaAtualizada);
  }
}
export default new UsuariosService();
