<template>
  <v-navigation-drawer width="30%" v-model="localDrawer" right temporary app>
    <v-list-item class="primary">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium white--text"
          >Historico de lançamento</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title
          >Historico de lançamento do {{ bimestre + 1 }}° bimestre</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <div v-if="plano.audits.length > 0">
      <v-list class="px-3" v-for="(audit, index) in plano.audits" :key="audit.id" dense>
        <v-list-item-title>
          <p class="font-weight-light subtitle-1 text-caption">
            Nome:
            <strong>{{ audit.username ? audit.username : "- - -" }}</strong>
          </p>
        </v-list-item-title>
        <v-list-item-title>
          <p class="font-weight-light subtitle-1 text-caption">
            Tipo de operação:
            <strong>{{ audit.event ? events[audit.event] : "- - -" }}</strong>
          </p>
        </v-list-item-title>
        <v-list-item-title>
          <p class="font-weight-light subtitle-1 text-caption">
            Data da operação:
            <strong>{{ audit.created_at ? dateFormat(audit.created_at) : "- - -" }}</strong>
            <strong> às {{ audit.created_at ? dateFormatHour(audit.created_at) : "- - -" }}</strong>
          </p>
        </v-list-item-title>
        <v-divider v-if="index + 1 != plano.audits.length"></v-divider>
      </v-list>
    </div>
    <v-list-item-title v-else>
      <strong class="subtitle-1 text-caption">Operações não encontradas</strong>
    </v-list-item-title>
  </v-navigation-drawer>
</template>

<script>
import { dateFormat, dateFormatHour } from "@/plugins/filters";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    gestao_id: {
      type: Number,
      required: true,
    },
    bimestre: {
      type: Number,
      required: true,
    },
  },
  watch: {
    value() {
      this.localDrawer = this.value;
    },
    async localDrawer() {
      this.$emit("input", this.localDrawer);
      if (this.localDrawer) {
        await this.historicoPlanosLancados();
      }
    },
  },
  computed: {},
  data() {
    return {
      localDrawer: this.value,
      dialog: false,
      plano: {
        audits: [],
      },
      dateFormat,
      dateFormatHour,
      events: {
        created: "Criado",
        updated: "Atualizado",
        deleted: "Deletado",
        restored: "Restaurados",
      },
      masculino: "https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png",
      feminino:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbUqkRN4QaEzCW4brgWElc7lv8HdwP8RyxJ89WdDWAoIO-rEUIACXkEXG4n2mDGMLuz4Q&usqp=CAU",
    };
  },
  methods: {
    async historicoPlanosLancados() {
      const response = await this.$services.gestoesDeAulasService.historicoPlanosLancados(
        this.gestao_id,
        this.bimestre
      );

      this.plano = response.historicoLancamento
        ? response.historicoLancamento
        : {
            audits: [],
          };
    },
  },
};
</script>

<style lang="scss" scoped></style>
