<template>
  <div>
    <template
      v-if="
        $constants.situacoesMatriculaCondition[
          removerEspaco(matricula.situacao ? matricula.situacao : '')
        ]
      "
    >
      <v-chip label color="error" small>{{ matricula.situacao }}</v-chip>
    </template>
    <template v-else>
      <span v-if="!mostrarResultado">- - -</span>
      <v-chip label color="warning" small v-else-if="mediaSemestral < notaMinima"
        >RECUPERAÇÃO</v-chip
      >
      <v-chip label color="success" small v-else>APROVADO</v-chip>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    media1: {
      type: [Number, String],
    },
    media2: {
      type: [Number, String],
    },
    recuperacao: {
      type: [Number, String],
    },
    notaMinima: {
      type: [Number],
      default: 6,
    },
    matricula: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mediaSemestral() {
      let result = 0;
      const Fnota1 = parseFloat(this.media1, 10) || 0;
      const Fnota2 = parseFloat(this.media2, 10) || 0;
      const Frec = parseFloat(this.recuperacao, 10) || 0;
      result = (Fnota1 + Fnota2) / 2;
      const mediaSemestral = Math.trunc(result * 10) / 10.0;

      if (!this.recuperacao && mediaSemestral < this.notaMinima) {
        return mediaSemestral;
      }
      if (mediaSemestral < this.notaMinima) {
        const maiorNota = Math.max(Fnota1, Fnota2);
        result = (maiorNota + Frec) / 2;

        const nota = Math.trunc(result * 10) / 10.0;
        return nota >= mediaSemestral ? nota : mediaSemestral;
      }
      return mediaSemestral;

      // return (parseFloat(this.media1 || 0, 10) + parseFloat(this.media2 || 0, 10)) / 2.0;
    },
    mostrarResultado() {
      return (
        !this.estaVazio(this.media1) ||
        !this.estaVazio(this.media2) ||
        !this.estaVazio(this.recuperacao)
      );
    },
  },
  methods: {
    estaVazio(n) {
      return n === undefined || n === null;
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
  },
};
</script>
