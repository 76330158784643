<template>
  <v-dialog :value="value" @input="(value) => $emit('change', value)" width="500" persistent>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
          <v-card-title class="text-h5"> Alterando a senha </v-card-title>
          <v-card-text>
            <e-label>Nova Senha</e-label>
            <ValidationProvider
              name="Senha"
              rules="required|min:4"
              v-slot="{ errors }"
              vid="password"
            >
              <v-text-field :error-messages="errors" type="password" v-model="form.password" solo />
            </ValidationProvider>
            <e-label>Confirme a nova senha</e-label>
            <ValidationProvider
              name="Confirme a senha"
              rules="required|min:4|confirmed:password"
              v-slot="{ errors }"
            >
              <v-text-field
                :error-messages="errors"
                type="password"
                v-model="form.password_confirm"
                solo
              />
            </ValidationProvider>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="rufous white--text"
              type="button"
              small
              @click="() => $emit('input', false)"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="secondary"
              type="submit"
              :disabled="submittingForm"
              :loading="submittingForm"
              small
            >
              Atualizar
            </v-btn>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        password: "",
        password_confirm: "",
      },
      submittingForm: false,
    };
  },
  methods: {
    async submitForm() {
      this.submittingForm = true;
      try {
        await this.$services.authService.atualizarSenha(this.form.password);
        this.$emit("input", false);
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
  },
};
</script>

<style></style>
