<template>
  <infinite-loading></infinite-loading>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    InfiniteLoading,
  },
};
</script>
