<template>
  <v-dialog :value="open" persistent max-width="450px">
    <v-card>
      <v-card-text>
        <div class="d-flex pt-3 pb-3 flex-column align-center">
          <img src="/img/logo.png" width="100" />
          <span class="pt-3 pb-3">{{ message }}</span>
          <v-progress-linear indeterminate color="secondary"></v-progress-linear>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    message: "",
    open: false,
  }),
  mounted() {
    this.$loaderService.setOpenFunction((message) => this.openModal(message));
    this.$loaderService.setCloseFunction(() => (this.open = false));
  },
  methods: {
    openModal(message) {
      this.open = true;
      this.message = message;
    },
  },
};
</script>

<style></style>
