<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h6 primary white--text"> Migração </v-card-title>
        <v-col class="pt-0 pb-0" cols="12">
          <v-checkbox v-model="check" label="Transferir aluno" :value="check"></v-checkbox>
        </v-col>
        <v-select
          v-if="check"
          class="px-3 my-5"
          :items="franquias"
          item-text="fantasia"
          item-value="id"
          :hint="`${franquiaSelecionada.fantasia}`"
          return-object
          @change="(event) => listarTurmasFranquiaSelect(event)"
          label="Selecione uma escola"
          v-model="franquiaSelecionada"
        >
        </v-select>
        <v-select
          v-if="check"
          class="px-3 my-5"
          :items="turmas"
          item-text="descricao"
          item-value="id"
          :hint="`${turmaSelecionada.descricao}`"
          return-object
          label="Selecione uma turma"
          v-model="turmaSelecionada"
        >
        </v-select>
        <v-select
          v-if="!check"
          class="px-3 my-5"
          :items="$constants.situacoesMatricula"
          label="Selecione uma situação"
          v-model="matricula.situacao"
        >
        </v-select>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="$emit('dialogChange', false)"> Fechar </v-btn>
          <v-btn color="primary" @click="enableSubmit" text> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <e-modal-confirm
      :confirmDialog="confirmDialog"
      :objectModal="objectModal"
      @changeModalConfirm="enableSubmit"
      @changeActionResponse="transferirMatricula"
    >
    </e-modal-confirm>
  </div>
</template>

<script>
export default {
  props: {
    matricula: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      check: false,
      turmas: [],
      franquias: [],
      turmaSelecionada: {},
      franquiaSelecionada: {},
      situacaoSelecionada: "",
      confirmDialog: false,
      objectModal: {
        title: "Migração",
        message: "Tem certeza que deseja executar está ação ?",
      },
    };
  },
  watch: {
    dialog: {
      async handler() {
        this.franquias = await this.$services.franquiasService.listAllSelect();
      },
    },
  },
  methods: {
    enableSubmit() {
      this.confirmDialog = !this.confirmDialog;
    },
    async listarTurmasFranquiaSelect(franquia) {
      const form = {
        franquia,
        matricula: this.matricula,
      };
      this.turmas = await this.$services.turmasService.listarTurmasFranquiaSelect(form);
    },
    async transferirMatricula() {
      this.$loaderService.open("Transferindo matrícula");
      this.$emit("changeDialog");
      this.confirmDialog = !this.confirmDialog;
      try {
        const form = {
          matriculaTransferida: {
            aluno_id: this.matricula.aluno_id,
            turma_id: this.turmaSelecionada.id,
            serie_id: this.matricula.serie_id,
            situacao: "RECEBIDA",
          },
          matricula: this.matricula,
          check: this.check,
        };
        this.matricula = await this.$services.matriculasService.transferirMatricula(form);
        this.$toast.success("Migração concluída com sucesso");
        this.$forceUpdate();
        window.location.reload();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
