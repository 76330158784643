// import { $Tawk } from "vue-tawk";
import store, { limparDadosBasicos, limparTodosOsDados } from "@/store";
import { $loaderService } from "@/plugins/ELoaderService";
import router from "@/router";
import User from "@/Models/User";
import Axios from "../plugins/Axios";

export class AuthService {
  static ACCESS_TOKEN_NAME = "access_token";

  async logar({ email, password }) {
    await Axios().get("/sanctum/csrf-cookie");
    const response = await Axios().post("/login", {
      email,
      password,
      device_name: this.getDeviceName(),
    });

    // $Tawk.$showWidget();
    this.setAccessToken(response.data);
    $loaderService.loadBasicDataIfNeeded();
    // if (window.Tawk_API.showWidget) window.Tawk_API.showWidget();
    this.syncUserWithStore();

    // this.verificarForcaDaSenha();
    store.dispatch("Auth/verificarForcaDaSenha");
  }

  async resetPassword(credentials) {
    const response = await Axios().post("/reset-password", {
      email: credentials.email,
      data_nascimento: credentials.data_nascimento,
      cpf: credentials.cpf,
    });
    return response;
  }

  async verificarForcaDaSenha() {
    await Axios().get("/verificar-forca-da-senha");
  }

  async getCurrentUser() {
    const response = await Axios().get("/current-user");
    return new User(response.data);
  }

  getDeviceName() {
    return `${navigator.appCodeName} - ${navigator.platform}`;
  }

  async logout() {
    // await Axios().get("/logout");
    store.commit("Auth/setUser", null);
    limparTodosOsDados();
    localStorage.removeItem(AuthService.ACCESS_TOKEN_NAME);
    $loaderService.close();
    // if (window.Tawk_API.hideWidget) window.Tawk_API.hideWidget();
  }

  setAccessToken(accessTokenObject) {
    localStorage.setItem(AuthService.ACCESS_TOKEN_NAME, accessTokenObject.access_token);
  }

  async syncUserWithStore() {
    if (this.isLoggedIn()) {
      const user = await this.getCurrentUser();

      // if (user) {
      //   const { data: hash } = await Axios().get("tawk-to-hash", { params: { email: user.email } });
      //   const tawkToUser = {
      //     name: user.name,
      //     email: user.email,
      //     hash,
      //   };
      //   $Tawk.$setAttributes(tawkToUser);
      // }

      store.commit("Auth/setUser", user);
    }
  }

  isLoggedIn() {
    return Boolean(this.getAccessToken());
  }

  getAccessToken() {
    try {
      return localStorage.getItem(AuthService.ACCESS_TOKEN_NAME);
    } catch (error) {
      return error;
    }
  }

  async updateCurrentUser(user) {
    const currentUser = store.getters["Auth/user"];
    if (!currentUser || !user) throw new Error("Usuário inexistente");
    const response = await Axios().put(`/users/me`, user);
    const updatedUser = response.data;
    await this.syncUserWithStore();
    // store.commit("Auth/setUser", updatedUser);
    return updatedUser;
  }

  async mudarDeAno(ano) {
    if (!ano) throw new Error("Ano escolhido inválido");
    const currentUser = store.getters["Auth/user"];
    await this.updateCurrentUser({ ...currentUser, ano_id: ano.id });
    window.location.reload();
    router.push({ path: "/" });
  }

  async mudarDeFranquia(franquia) {
    if (!franquia) throw new Error("Franquia escolhida é inválida");
    const currentUser = store.getters["Auth/user"];
    await this.updateCurrentUser({ ...currentUser, configuracao_id: franquia.id });
    limparDadosBasicos();
    router.push({ path: "/" });
  }

  async atualizarSenha(password) {
    await Axios().put("/update-password", { password });
    store.dispatch("Auth/verificarForcaDaSenha");
  }
}

export default new AuthService();
