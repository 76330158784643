import Axios from "@/plugins/Axios";
import Aluno from "@/Models/Aluno";
import Matricula from "@/Models/Matricula";
import store from "@/store";

class AlunosService {
  async syncAll() {
    const response = await Axios().get("alunos");
    return response.data.map((aluno) => new Aluno(aluno));
  }

  async syncAllPaginate(query, pageNumber) {
    const response = await Axios().get(`alunos/paginate?page=${pageNumber}&query=${query}`);
    // return response.data.map((aluno) => new Aluno(aluno));
    return response;
  }

  async pegarAluno(id) {
    const response = await Axios().get(`alunos/${id}`);
    const data = new Aluno(response.data);
    return data;
  }

  async criarAluno(aluno) {
    const response = await Axios().post("alunos", aluno);
    const novaAluno = response.data;
    store.commit("Alunos/adicionarAluno", novaAluno);
    return novaAluno;
  }

  async getAlunoComINEP(codigoInep) {
    const response = await Axios().get(`alunos/codigo-inep/${codigoInep}`);
    return response.data ? new Aluno(response.data) : null;
  }

  async atualizarAluno(aluno) {
    const response = await Axios().put(`alunos/${aluno.id}`, aluno);
    const alunoAtualizada = response.data;
    store.commit("Alunos/atualizarAluno", alunoAtualizada);
    return alunoAtualizada;
  }

  async deletarAluno(aluno) {
    const response = await Axios().delete(`alunos/${aluno.id}`);
    const alunoDeletada = response.data;
    store.commit("Alunos/deletarAluno", aluno.id);
    return alunoDeletada;
  }

  async recuperarAluno(aluno) {
    return this.atualizarAluno({ ...aluno, deleted_at: null });
  }

  async pesquisaAvancada(form) {
    const response = await Axios().post(`alunos/pesquisa-avancada`, form);
    const alunos = response.data.map((aluno) => new Aluno(aluno));
    return alunos;
  }

  async matriculasAluno(form) {
    const response = await Axios().post(`alunos/matriculas-aluno`, form);
    const matriculas = response.data.map((matricula) => new Matricula(matricula));
    return matriculas;
  }

  async faixaEtaria() {
    const response = await Axios().get(`alunos/faixa-etaria`);
    return response;
  }

  async zona() {
    const response = await Axios().get(`alunos/zona`);
    return response;
  }

  async generos() {
    const response = await Axios().get(`alunos/generos`);
    return response;
  }

  async deficientes() {
    const response = await Axios().get(`alunos/deficientes`);
    return response;
  }
}

export default new AlunosService();
